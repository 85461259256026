import { Link, useNavigate } from 'react-router-dom'
import '../style/navbar.css'
import logo from '../assets/images/LogoTextBM.png';
import * as React from 'react';
import { Badge, Button, ThemeProvider, createTheme } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { TabUnselectedSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function Navbar({  }) {
    const theme = createTheme({
      palette: {
        primary: {
          main: '#36454f'
        }
      }
    });

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return <ul className="navbar">
        <div><Link className='navbar' to="/"><div className='button' id='no-hover'>
            <img className='logo' src={logo} alt='Logo'/>
        </div></Link></div>
        <div id='right'>
            <ThemeProvider theme={theme}>
                <Button variant='outlined' endIcon={<SchoolIcon/>} onClick={() => {
                    if (i18n.language === 'es') {
                      i18n.changeLanguage('ca');
                    } else {
                      i18n.changeLanguage('es');
                    }
                }}>{i18n.language === 'es'? "CATALÀ" : "ESPAÑOL"}</Button>
                <Button variant='outlined' endIcon={<SchoolIcon/>} onClick={() => {
                    window.open("https://campus.bitminds.es/");
                }}>CAMPUS</Button>
            </ThemeProvider>
        </div>
    </ul>
}