import { Button } from "@mui/material";

export function CustomDividerVertical({ color, width, extraMargin, verticalMargin="0px" }) {
    return <div style={{backgroundColor: color, width: width, margin:`${verticalMargin} ${extraMargin}`}}></div>;
}

export function CustomDividerHorizontal({ color, height, extraMargin, horizontalMargin="0px" }) {
    return <div style={{backgroundColor: color, height:height, margin:`${extraMargin} ${horizontalMargin}`}}></div>;
}

export function RoundButton({ text, onClick=()=>{}, endIcon, startIcon }) {
    return <Button variant="outlined" style={{borderRadius: 90, borderWidth:'2px', width:'-webkit-fill-available'}} color="primary" endIcon={endIcon} onClick={onClick}>{text}</Button>
}