import { Carousel } from "react-bootstrap";
import NavBar from "../widgets/navbar";
import '../style/homePage.css';
import { useState } from "react";
import LoadingBarApp from "../components/loading";
import { db } from "../firebase/firebase";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { Course } from '../implementation/course'
import { CustomDividerHorizontal, CustomDividerVertical, RoundButton } from "../components/customUi";
import { createTheme, Divider, ThemeProvider } from "@mui/material";
import { DownloadOutlined, OpenInNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export default function HomePage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
    });
    const middleScreen = useMediaQuery({
        query: '(min-width: 720px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 530px)'
    });

    const [carrouselCourses, setCarrouselCourses] = useState(null);

    async function getCarrouselCourses() {
        try {
            const courses = [];

            const homePageData = await getDoc(doc(db, 'academyWebInfo', 'homePage'));

            await Promise.all(homePageData.get('carrouselCourses').map(async courseId => {
                const courseData = await getDoc(doc(db, 'courses', courseId));

                const course = Course.fromDataBase(courseData);

                const priceRef = await getDocs(query(collection(db, 'products', course.getId(), 'prices'), where("active", "==", true)));
                course.updatePrice(priceRef.docs[0].get('unit_amount'));
                if (priceRef.docs[1])
                    course.updateSecondPrice(priceRef.docs[1].get('unit_amount'));

                courses.push(course);
            }));

            console.log(courses);
            setCarrouselCourses(courses);
        } catch (e) {
            console.error(e);
        }
    }

    if (carrouselCourses) {
        return <div>
            <NavBar/>
            <div style={{height:'64px', backgroundColor:'#708090'}}/>
            <Carousel className="home-page-carrousel" controls={false}>
                {carrouselCourses.map((course, index) => {
                    return <Carousel.Item key={index}>
                        {isLaptop
                            ? <div className="carousel-course-region">
                                <div className="carousel-course-presentation">
                                    <div style={{fontSize:'20px'}}>{t('course.title')}</div>
                                    <div>{course.getTitle()}</div>
                                    <div style={{fontSize:'20px'}}>{t('course.info.age')}</div>
                                    <div style={{height:'30px'}}/>
                                    <div className="carousel-extra-info">
                                        <div className="data-part">
                                            <div className="important-text">{course.getTotalHours()}</div>
                                            <div>{t('course.info.hours')}</div>
                                        </div>
                                        <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                        <div className="data-part">
                                            <div className="important-text">{course.getTotalExercices()}</div>
                                            <div>{t('course.info.exercices')}</div>
                                        </div>
                                        <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                        <div className="data-part">
                                            <div className="important-text">{course.getTotalPages()}</div>
                                            <div>{t('course.info.pages')}</div>
                                        </div>
                                    </div>
                                    <div style={{height:'40px'}}/>
                                    <div style={{display:'flex'}}>
                                        <div>
                                            <div>
                                                {t('course.level')}: {t(course.getLevelString())}
                                            </div>
                                            <div className="idioma-course">
                                                {course.getIdiomaIcon()}
                                                <div style={{width:5}}/>
                                                <Divider orientation="vertical" flexItem style={{color:'#36454f'}}/>
                                                {course.getLenguajeIcons()}
                                            </div>
                                        </div>
                                        <div style={{marginLeft:'auto', paddingRight:'3px'}}>
                                            <div style={{textDecoration:course.getSecondPrice()? 'line-through' : null, textAlign:'end'}}>
                                                {(course.getPrice()/100).toFixed(2)}€
                                            </div>
                                            {course.getSecondPrice()
                                                ? <div style={{fontSize: '20px', display:'flex', alignItems:'center'}}>
                                                    <div style={{borderRadius:'6px', marginRight: '5px', backgroundColor:'red', padding:'3px'}}>
                                                        -{(((course.getPrice()/100)-(course.getSecondPrice()/100))/(course.getPrice()/100)*100).toFixed(2)}%
                                                    </div>
                                                    <div>
                                                        {(course.getSecondPrice()/100).toFixed(2)}€
                                                    </div>
                                                </div>
                                                : <div></div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{height:'40px'}}/>
                                    <div style={{height:'-webkit-fill-abailable', display:'flex', justifyContent:'center'}}>
                                        <ThemeProvider theme={createTheme({
                                            palette: {
                                                primary: {
                                                    main: "#36454f"
                                                }
                                            }
                                        })}>
                                            <div style={{display:'flex', justifyContent:'center', width:'-webkit-fill-available'}}>
                                                <RoundButton text={t('course.actions.moreInfo')} onClick={() => {
                                                    window.open(`https://campus.bitmasters.es/course/${course.getId()}`, "_blank", "noreferrer");
                                                }} endIcon={<OpenInNew/>}/>
                                                <div style={{width:'5%'}}/>
                                                <RoundButton text={t('course.actions.download')} onClick={() => {}} endIcon={<DownloadOutlined/>}/>
                                            </div>
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div style={{marginLeft:'auto', width:'50%'}}>
                                    <img src={course.getPhotoUrl()} width={'100%'} className="carousel-presentation-img"/>
                                </div>
                            </div>
                            : <div className="carousel-course-region no-flex">
                                <img src={course.getPhotoUrl()} width={'100%'} className="carousel-presentation-img no-flex"/>
                                <div className="carousel-course-presentation no-flex" style={smallScreen
                                ? {}
                                : {padding:'40px 50px'}
                            }>
                                    <div style={{fontSize:'20px'}}>{t('course.title')}</div>
                                    <div>{course.getTitle()}</div>
                                    <div style={{fontSize:'20px'}}>{t('course.info.age')}</div>
                                    <div style={{height:'30px'}}/>
                                    {middleScreen
                                        ? <div className="carousel-extra-info">
                                            <div className="data-part">
                                                <div className="important-text">{course.getTotalHours()}</div>
                                                <div>{t('course.info.hours')}</div>
                                            </div>
                                            <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                            <div className="data-part">
                                                <div className="important-text">{course.getTotalExercices()}</div>
                                                <div>{t('course.info.exercices')}</div>
                                            </div>
                                            <CustomDividerVertical color="#36454f" width="2px" extraMargin="10px"/>
                                            <div className="data-part">
                                                <div className="important-text">{course.getTotalPages()}</div>
                                                <div>{t('course.info.pages')}</div>
                                            </div>
                                        </div>
                                        : <div className="carousel-extra-info no-flex">
                                            <div className="data-part no-flex">
                                                <div className="important-text">{course.getTotalHours()}</div>
                                                <div>{t('course.info.hours')}</div>
                                            </div>
                                            <div style={{height:'40px'}}/>
                                            <div className="data-part no-flex">
                                                <div className="important-text">{course.getTotalExercices()}</div>
                                                <div>{t('course.info.exercices')}</div>
                                            </div>
                                            <div style={{height:'40px'}}/>
                                            <div className="data-part no-flex">
                                                <div className="important-text">{course.getTotalPages()}</div>
                                                <div>{t('course.info.pages')}</div>
                                            </div>
                                        </div>
                                    }
                                    <div style={{height:'40px'}}/>
                                    <div style={{display:'flex'}}>
                                        <div>
                                            <div>
                                                {t('course.level')}: {t(course.getLevelString())}
                                            </div>
                                            <div className="idioma-course">
                                                {course.getIdiomaIcon()}
                                                <div style={{width:5}}/>
                                                <Divider orientation="vertical" flexItem style={{color:'#36454f'}}/>
                                                {course.getLenguajeIcons()}
                                            </div>
                                        </div>
                                        <div style={{marginLeft:'auto', paddingRight:'3px'}}>
                                            <div style={{textDecoration:course.getSecondPrice()? 'line-through' : null, textAlign:'end'}}>
                                                {(course.getPrice()/100).toFixed(2)}€
                                            </div>
                                            {course.getSecondPrice()
                                                ? <div style={{fontSize: '20px', display:'flex', alignItems:'center'}}>
                                                    <div style={{borderRadius:'6px', marginRight: '5px', backgroundColor:'red', padding:'3px'}}>
                                                        -{(((course.getPrice()/100)-(course.getSecondPrice()/100))/(course.getPrice()/100)*100).toFixed(2)}%
                                                    </div>
                                                    <div>
                                                        {(course.getSecondPrice()/100).toFixed(2)}€
                                                    </div>
                                                </div>
                                                : <div></div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{height:'40px'}}/>
                                    <div style={{height:'-webkit-fill-abailable', display:'flex', justifyContent:'center'}}>
                                        <ThemeProvider theme={createTheme({
                                            palette: {
                                                primary: {
                                                    main: "#36454f"
                                                }
                                            }
                                        })}>
                                            {smallScreen
                                                ? <div style={{display:'flex', justifyContent:'center', width:'-webkit-fill-available'}}>
                                                    <RoundButton text={t('course.actions.moreInfo')} onClick={() => {
                                                        window.open(`https://campus.bitmasters.es/course/${course.getId()}`, "_blank", "noreferrer");
                                                    }} endIcon={<OpenInNew/>}/>
                                                    <div style={{width:'5%'}}/>
                                                    <RoundButton text={t('course.actions.download')} onClick={() => {}} endIcon={<DownloadOutlined/>}/>
                                                </div>
                                                : <div style={{width:'-webkit-fill-available'}}>
                                                    <RoundButton text={t('course.actions.moreInfo')} onClick={() => {
                                                        window.open(`https://campus.bitmasters.es/course/${course.getId()}`, "_blank", "noreferrer");
                                                    }} endIcon={<OpenInNew/>}/>
                                                    <div style={{width:'5%'}}/>
                                                    <RoundButton text={t('course.actions.download')} onClick={() => {}} endIcon={<DownloadOutlined/>}/>
                                                </div>
                                            }
                                        </ThemeProvider>
                                    </div>
                                </div>
                            </div>
                        }
                    </Carousel.Item>;
                })}
            </Carousel>
            {i18n.language==='es'
                ? <div id="lan-es">
                    <div className="page-section">
                        <div style={{height:'40px'}}/>
                        <div className="important-title">
                            BitMasters Academy
                        </div>
                        <div className="important-description">
                            La academia de programación para toda la familia.
                            Con nuestros cursos, los más pequeños de casa, podrán aprender paso a paso lo más importante
                            de la industria de la programación.
                            Des de las bases para poder y saber programar hasta robótica y manejo de bases de datos.
                            Con nuestros itinerarios dedicados y construidos para cada alumno y con el tiempo podrán avanzar, aprender y crecer.
                        </div>
                    </div>
                    <div style={{height:'40px'}}/>
                    <div className="contrast-section" id="metodo-aprendizaje">
                        <div className="important-title">
                            Nuestro método de aprendizaje
                        </div>
                        <div className="important-description">
                            <div>
                                Nos basamos en un método de aprendizaje autónomo y al ritmo de cada uno.
                                Pero nuestros alumnos siempre van acompañados por nuestros profesionales a través de los foros de los cursos,
                                además, pueden disfrutar de videollamadas individuales con nuestros profesores para resolver dudas concretas.
                            </div>
                            <div className="no-important-sectiption">
                                <div className="no-important-title">¿Empezamos?</div>
                                <div>Esta es nuestra guía para escoger los cursos que os interesan.</div>
                                <div style={{height:'10px'}}/>
                                <div className="minor-title">¿Sabemos lo que queremos?</div>
                                <div style={{marginLeft:'40px'}}>
                                    Si tenemos claro lo que queremos, no se hable más, solo tenemos que encontrar el curso. Eso se puede hacer a través del <span><a className="text-link" href="/browse">explorador de cursos</a></span>.
                                </div>
                                <div style={{marginLeft:'40px'}}>
                                    Si no lo tenemos claro, tenemos distintas opciones:
                                    <ol>
                                        <li>Se puede poner en contacto con nosotros a través de la dirección de correo electrónico <span><a className="text-link" href="mailto:info@bitmasters.es">info@bitmasters.es</a></span> para poder ayudarle.</li>
                                        <li>Podemos diseñar un plan de estudios a largo plazo para nuestros alumnos. Este plan de estudios no queda cerrado y se puede modificar, ya que se paga el curso que se quiere cursar en cada momento, así que a lo largo de los cursos si el alumno cambia de gustos e intereses también puede cambiar su plan de estudios. Para esto puede leer la <span><a className="text-link" href="#guia-plan-estudios">guía para el plan de estudios personalizado</a></span> o puede ponerse en contacto con nosotros a través de la dirección de correo electrónico <span><a className="text-link" href="mailto:info@bitmasters.es?subject=Plan%20de%20estudios%20personalizado">info@bitmasters.es</a></span>.</li>
                                        <li>Puede explorar los distintos cursos que tenemos a su disposición para acabar de decidirse a través del <span><a className="text-link" href="/browse">explorador de cursos</a></span>.</li>
                                    </ol>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div className="minor-title">¿Cuándo podemos empezar el curso?</div>
                                <div style={{marginLeft:'40px'}}>
                                    Cualquier curso puede empezarse en cualquier momento, solo es necesario pagarlo y en pocos segundos puede empezar a aprender.
                                </div>
                                <div style={{marginLeft:'40px'}}>
                                    Si quiere conocer más información sobre nuestros cursos y cómo funciona nuestro campus, puede leer la <span><a className="text-link" href="#guia-campus">guía del campus</a></span>.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" id="guia-plan-estudios">
                        <div style={{height:'40px'}}/>
                        <div className="important-title">
                            Guía para el plan de estudios
                        </div>
                        <div className="important-description">
                            <div>
                                Para poder obtener un plan de estudios personalizado, debes ponerte en contacto con nosotros a través del correo electrónico <span><a className="text-link" href="mailto:info@bitmasters.es?subject=Plan%20de%20estudios%20personalizado">info@bitmasters.es</a></span>.
                            </div>
                            <div className="no-important-sectiption">
                                <div className="no-important-title">Debes tener en cuenta</div>
                                <div>Para poder elaborar un plan de estudios personalizado necesitamos la siguiente información:</div>
                                <div style={{height:'10px'}}/>
                                <div style={{marginLeft:'40px'}} className="minor-title">Nivel del alumno</div>
                                <div style={{marginLeft:'80px'}}>
                                    ¿El alumno ha programado alguna vez? ¿Qué lenguajes de programación sabe programar el alumno?
                                    <div>Esto es importante para saber en qué punto se encuentra el estudiante al empezar sus estudios con nosotros.</div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div style={{marginLeft:'40px'}} className="minor-title">Intereses del alumno</div>
                                <div style={{marginLeft:'80px'}}>
                                    ¿El alumno tiene algún interés en especial? Como programación web, de videojuegos o robótica.
                                    <div>Esto es importante para saber hacia dónde encaminar los estudios y aprendizajes del estudiante.</div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div style={{marginLeft:'40px'}} className="minor-title">Cualquier otra información</div>
                                <div style={{marginLeft:'80px'}}>
                                    Cualquier otra información puede sernos de ayuda para crear el plan de estudios personalizado para cada alumno.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="guia-campus">
                        <div style={{height:'40px'}}/>
                        <div className="contrast-section">
                        <div className="important-title">
                            Guía del campus
                        </div>
                        <div className="important-description">
                            <div>
                                Es importante que antes de empezar a estudiar con nosotros se entienda bien el funcionamiento del campus, ya que en él será donde se desarrollará toda la actividad estudiantil del alumno.
                            </div>
                            <div className="no-important-sectiption">
                                <div className="no-important-title">Lo más importante</div>
                                <div>Puedes empezar registrándote en nuestra plataforma. De esta manera podrás dejarnos tus datos y tendrás acceso al campus. Puedes hacerlo a través de este <span><a className="text-link" href="https://bitmasters.es/logup" target="_blank">enlace</a></span>. Acuerdate de verificar el correo electrónico a través de un enlace que recibirás en tu correo electrónico.</div>
                                <div style={{height:'10px'}}/>
                                <div className="minor-title">Primeros pasos</div>
                                <div style={{marginLeft:'40px'}}>
                                    Una vez registrado como usuario en nuestra plataforma, puedes proporcionarnos tus datos a través de este <span><a className="text-link" href="https://campus.bitmasters.es/dashboard/account" target="_blank">enlace</a></span>.
                                    <div>
                                        Además, para poder escribir y participar en los foros deberás haber configurado el perfil público de antemano, así que es un buen momento para hacerlo. Puedes hacer desde el siguiente <span><a className="text-link" href="https://campus.bitmasters.es/dashboard/profile" target="_blank">enlace</a></span>.
                                    </div>
                                    <div>
                                        Ahora es el momento de pagar el curso que te interesa, puedes encontrar el curso a través del <span><a className="text-link" href="https://campus.bitmasters.es/browse" target="_blank">buscador</a></span> del campus.
                                    </div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div className="minor-title">El campus para los alumnos</div>
                                <div style={{marginLeft:'40px'}}>
                                    Una vez se haya comprado un curso, es hora de empezar a aprender. Para eso primero debemos acceder el curso, esto se puede hacer des de la página principal del campus donde aparecerán los cursos que hayas comprado y aún no hayas finalizado.
                                    <div>
                                        La realización y funcionamiento del curso es fácil e intuitivo, pero para más información puedes ver este <span><a className="text-link" href="https://www.youtube.com/@BitMastersOficial" target="_blank">video</a></span>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                : <div id="lan-ca">
                    <div className="page-section">
                        <div style={{height:'40px'}}/>
                        <div className="important-title">
                            BitMasters Academy
                        </div>
                        <div className="important-description">
                            L'acadèmia de programació per a tota la família.
                            Amb els nostres cursos, els més petits de casa, podran aprendre pas a pas el més important de la indústria de la programació.
                            Des de les bases per poder i saber programar fins a robòtica i administració de bases de dades.
                            Amb els nostres itineraris dedicats i construïts per a cada alumne i amb el temps podran avançar, aprendre i créixer.
                        </div>
                    </div>
                    <div style={{height:'40px'}}/>
                    <div className="contrast-section" id="metodo-aprendizaje">
                        <div className="important-title">
                            El nostre mètode d'aprenentatge
                        </div>
                        <div className="important-description">
                            <div>
                                Ens vessem en un mètode d'aprenentatge autònom i al ritme de cadascú.
                                Però els nostres alumnes sempre van acompanyats pels nostres professionals a través dels fòrums dels cursos, a més, poden gaudir de videotrucades individuals amb els nostres professors per resoldre dubtes concrets.
                            </div>
                            <div className="no-important-sectiption">
                                <div className="no-important-title">Comencem?</div>
                                <div>Aquesta és la nostra guia per escollir els cursos que us interessin.</div>
                                <div style={{height:'10px'}}/>
                                <div className="minor-title">Sabem el que volem?</div>
                                <div style={{marginLeft:'40px'}}>
                                    Si tenim clar el que volem, no se'n parli més, només hem de trobar el curs. Això es pot fer a través del <span><a className="text-link" href="/browse">explorador de cursos</a></span>.
                                </div>
                                <div style={{marginLeft:'40px'}}>
                                    Si no ho tenim clar, tenim diverses opcions:
                                    <ol>
                                        <li>Es pot posar en contacte amb nosaltres a través de l'adreça de correu electrònic <span><a className="text-link" href="mailto:info@bitmasters.es">info@bitmasters.es</a></span> per poder ajudar-lo.</li>
                                        <li>Podem dissenyar un pla d'estudis a llarg termini per als nostres alumnes. Aquest pla d'estudis no queda tancat i es pot modificar, ja que es paga el curs que es vol cursar en cada moment, així que al llarg dels cursos si l'alumne canvia de gustos i interessos, també pot canviar el seu pla d'estudis. Per això pot llegir la <span><a className="text-link" href="#guia-plan-estudios">guia pel pla d'estudis personalitzat</a></span> o pot posar-se en contacte amb nosaltres a través de l'adreça de correu electrònic <span><a className="text-link" href="mailto:info@bitmasters.es?subject=Plan%20de%20estudios%20personalizado">info@bitmasters.es</a></span>.</li>
                                        <li>Pot explorar els diferents cursos que tenim a la seva disposició per acabar de decidir-se a través del <span><a className="text-link" href="/browse">explorador de cursos</a></span>.</li>
                                    </ol>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div className="minor-title">Quan podem començar el curs?</div>
                                <div style={{marginLeft:'40px'}}>
                                    Qualsevol curs pot començar-se en qualsevol moment, només és necessari pagar-lo i en pocs segons pot començar a aprendre.
                                </div>
                                <div style={{marginLeft:'40px'}}>
                                    Si vol conèixer més informació sobre els nostres cursos i com funciona el nostre campus pot llegir la <span><a className="text-link" href="#guia-campus">guia del campus</a></span>.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section" id="guia-plan-estudios">
                        <div style={{height:'40px'}}/>
                        <div className="important-title">
                            Guia per al pla d'estudis
                        </div>
                        <div className="important-description">
                            <div>
                                Per poder obtenir un pla d'estudis personalitzat has de posar-te en contacte amb nosaltres a través del correu electrònic <span><a className="text-link" href="mailto:info@bitmasters.es?subject=Plan%20de%20estudios%20personalizado">info@bitmasters.es</a></span>.
                            </div>
                            <div className="no-important-sectiption">
                                <div className="no-important-title">Has de tenir un compte</div>
                                <div>Per poder elaborar un pla d'estudis personalitzat necessitem la següent informació:</div>
                                <div style={{height:'10px'}}/>
                                <div style={{marginLeft:'40px'}} className="minor-title">Nivell de l'alumne</div>
                                <div style={{marginLeft:'80px'}}>
                                    L'alumne ha programat alguna vegada? Quins llenguatges de programació sap programar l'alumne?
                                    <div>Això és important per saber en quin punt es troba l'estudiant en començar els seus estudis amb nosaltres.</div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div style={{marginLeft:'40px'}} className="minor-title">Interessos de l'alumne</div>
                                <div style={{marginLeft:'80px'}}>
                                    L'alumne té algun interès en especial? Com programació web, de videojocs o robòtica.
                                    <div>Això és important per saber cap a on encaminar els estudis i aprenentatges de l'estudiant.</div>
                                </div>
                                <div style={{height:'10px'}}/>
                                <div style={{marginLeft:'40px'}} className="minor-title">Qualsevol altra informació</div>
                                <div style={{marginLeft:'80px'}}>
                                    Qualsevol altra informació pot ser-nos d'ajuda per a crear el pla d'estudis personalitzat per a cada alumne.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="guia-campus">
                        <div style={{height:'40px'}}/>
                        <div className="contrast-section">
                            <div className="important-title">
                                Guia del campus
                            </div>
                            <div className="important-description">
                                <div>
                                    És important que abans de començar a estudiar amb nosaltres s'entengui bé el funcionament del campus, ja que en aquest serà on es desenvoluparà tota l'activitat estudiantil de l'alumne.
                                </div>
                                <div className="no-important-sectiption">
                                    <div className="no-important-title">El més important</div>
                                    <div>Pot començar registrant-se a la nostra plataforma. D'aquesta manera podràs deixar-nos les teves dades i tindràs accés al campus. Això pots fer-ho a través del següent <span><a className="text-link" href="https://bitmasters.es/logup" target="_blank">enllaç</a></span>. Recorda verificar el correu electrònic a través d'un enllaç que rebràs en el teu correu electrònic.</div>
                                    <div style={{height:'10px'}}/>
                                    <div className="minor-title">Primers passos</div>
                                    <div style={{marginLeft:'40px'}}>
                                        Una vez registrado como usuario en nuetra plataforma puedes proporcionarnos tus datos a través de este <span><a className="text-link" href="https://campus.bitmasters.es/dashboard/account" target="_blank">enlace</a></span>.
                                        <div>
                                            Además, para poder escribir y participar en los foros deberás haber configurado el perfil público de antemano, asi que es un buen momento para hacerlo. Puedes hacer desde el siguiente <span><a className="text-link" href="https://campus.bitmasters.es/dashboard/profile" target="_blank">enlace</a></span>.
                                        </div>
                                        <div>
                                            Ahora es el momento de pagar el curso que te interesa, puedes encontrar el curso a través del <span><a className="text-link" href="https://campus.bitmasters.es/browse" target="_blank">buscador</a></span> del campus.
                                        </div>
                                    </div>
                                    <div style={{height:'10px'}}/>
                                    <div className="minor-title">El campus para los alumnos</div>
                                    <div style={{marginLeft:'40px'}}>
                                        Una vez se haya comprado un curso, es hora de 
                                        <div>
                                            Además, para poder escribir y participar en los foros deberás haber configurado el perfil público de antemano, asi que es un buen momento para hacerlo. Puedes hacer desde el siguiente <span><a className="text-link" href="https://campus.bitmasters.es/dashboard/profile" target="_blank">enlace</a></span>.
                                        </div>
                                        <div>
                                            Ahora es el momento de pagar el curso que te interesa, puedes encontrar el curso a través del <span><a className="text-link" href="https://campus.bitmasters.es/browse" target="_blank">buscador</a></span> del campus.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>;
    }

    getCarrouselCourses();

    return <div>
        <NavBar/>
        <LoadingBarApp/>
    </div>;
}