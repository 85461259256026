import { Box, createTheme, LinearProgress, ThemeProvider } from "@mui/material";

export default function LoadingBarApp() {
    return <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={createTheme({
        palette: {
          primary: {
            main: '#36454f'
          }
        }
      })}>
        <LinearProgress/>
      </ThemeProvider>
    </Box>;
}