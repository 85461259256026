import { Tooltip } from '@mui/material';
import pythonLogo from '../assets/icons/python.svg';
import arduinoLogo from '../assets/icons/arduino.svg';
import catIcon from '../assets/lang/ca.svg';
import espIcon from '../assets/lang/es.svg';
import "../style/course-card.css";
import '../style/file-download.css';

export class Course {
    constructor(title, id, photoUrl, active, description, capitulos, idioma, level, max_age, min_age, users_num, valorations_num, valoration, lenguajes, video, price, secondPrice, totalExercices, totalHours, totalPages) {
        this.title = title;
        this.id = id;
        this.photoUrl = photoUrl;
        this.active = active;
        this.description = description;
        this.capitulos = capitulos;
        this.idioma = idioma;
        this.level = level;
        this.max_age = max_age;
        this.min_age = min_age;
        this.users_num = users_num;
        this.valorations_num = valorations_num;
        this.valoration = valoration;
        this.lenguajes = lenguajes;
        this.video = video;
        this.price = price;
        this.secondPrice = secondPrice;
        this.totalExercices = totalExercices;
        this.totalHours = totalHours;
        this.totalPages = totalPages;
    }

    static fromDataBase(doc) {
        try {
            return new Course(doc.get('title'), doc.id, doc.get('photo'), doc.get('active'), doc.get('description'), doc.get('capitulos'), doc.get('idioma'), doc.get('level'), doc.get('max_age'), doc.get('min_age'), doc.get('users').length, 0, 0, doc.get('lenguajes'), doc.get("video"), null, null, doc.get('totalExercices'), doc.get('totalHours'), doc.get('totalPages'));
        } catch (e) {
            console.log(e);
        }
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        const retStr = this.description;
        console.log(retStr);
        console.log(retStr.replace("\n", "<br>"));
        return retStr.replace(/\n/g, "<br>");
    }

    getId() {
        return this.id;
    }

    getPhotoUrl() {
        return this.photoUrl;
    }

    getUsersNum() {
        return this.users_num;
    }

    getValorationsNum() {
        return this.valorations_num;
    }

    getValoration() {
        return this.valoration;
    }

    getLenguajes() {
        return this.lenguajes;
    }

    getCaps() {
        return this.capitulos;
    }

    getCapsLen() {
        return this.capitulos.length;
    }

    getLevelString() {
        switch(this.level) {
            case 1:
                return 'browse.level.basic';
            case 2:
                return 'browse.level.basicDedicated';
            case 3:
                return 'browse.level.dedicated';
            case 4:
                return 'browse.level.advanced';
            default:
                return "";
        }
    }

    getIdiomaIcon() {
        switch(this.idioma) {
            case "CAT":
                return <Tooltip key={"cat"} title={"Català"}><img src={catIcon} className='idioma-icon' width={20} style={{borderRadius:0}}/></Tooltip>;
            case "ESP":
                return <Tooltip key={"esp"} title={"Español"}><img src={espIcon} className='idioma-icon' width={20} style={{borderRadius:0}}/></Tooltip>;
        }
    }

    getLenguajeIcons() {
        var retVal = [];
        
        console.log("Lenguajes: ");
        console.log(this.lenguajes);
        this.lenguajes.forEach((item) => {
            console.log("Item: " + item);

            switch (item) {
                case "python":
                    retVal.push(<Tooltip key={"python"} title={"Python"}><img src={pythonLogo} className='lenguaje-icon' width={20}/></Tooltip>);
                    break;
                case "arduino":
                    retVal.push(<Tooltip key={"arduino"} title={"Arduino"}><img src={arduinoLogo} className='lenguaje-icon' width={20}/></Tooltip>);
                    break;
            }
        });

        return retVal;
    }

    getTotalCaps() {
        return this.capitulos.length;
    }

    getIdioma() {
        return this.idioma;
    }

    getVideoUrl() {
        return this.video;
    }

    getPrice() {
        return this.price;
    }

    getSecondPrice() {
        return this.secondPrice;
    }

    getTotalExercices() {
        return this.totalExercices;
    }

    getTotalHours() {
        return this.totalHours;
    }

    getTotalPages() {
        return this.totalPages;
    }

    setValorationsNum(valorations_num) {
        this.valorations_num = valorations_num;
    }

    setValoration(valoration) {
        this.valoration = valoration;
    }

    updatePrivateCourse(privateCourse) {
        this.privateCourse = privateCourse;
    }

    updatePrice(price) {
        this.price = price;
    }

    updateSecondPrice(secondPrice) {
        this.secondPrice = secondPrice;
    }

    updateProgress(progress) {
        this.progress = progress;
    }
}