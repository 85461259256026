import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            es: {
                translation: {
                    browse: {
                        level: {
                            title: "Nivel del curso",
                            basic: "Básico",
                            basicDedicated: "Básico dedicado",
                            dedicated: "Dedicado",
                            advanced: "Avanzado",
                        },
                    },
                    course: {
                        level: "Nivel",
                        buyNow: "Comprar ahora",
                        title: "Curso de",

                        info: {
                            hours: "horas de videos de aprendizaje",
                            exercices: "ejercicios de autoaprendizaje",
                            pages: "páginas DIN-A4 de aprendizaje",
                            age: "para niñas y niños de 12 a 18 años"
                        },

                        actions: {
                            moreInfo: "Más información",
                            download: "Índice de contenidos"
                        }
                    },
                }
            },
            ca: {
                translation: {
                    browse: {
                        level: {
                            title: "Nivell del curs",
                            basic: "Bàsic",
                            basicDedicated: "Bàsic dedicat",
                            dedicated: "Dedicat",
                            advanced: "Avançat"
                        },
                    },
                    course: {
                        level: "Nivell",
                        buyNow: "Comprar ara",
                        
                        title: "Curs de",

                        info: {
                            hours: "hores de vídeos d'autoaprenentatge",
                            exercices: "exercicis d'autoaprenentatge",
                            pages: "pàgines DIN-A4 d'autoaprenentatge",
                            age: "per a nenes i nens de 12 a 18 anys"
                        },

                        actions: {
                            moreInfo: "Més informació",
                            download: "Índex de continguts"
                        }
                    },
                }
            }
        }
    });

export default i18n;